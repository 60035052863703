/* Helpers */
.flex {
    display: flex;
}

.max_desktop,
table {
    max-width: 1440px;
}

.actions {
    gap: 0.5rem;
}

.mb_0 {
    margin-bottom: 0;
}

.flex_wrap {
    flex-wrap: wrap;
}

.flex_col {
    flex-direction: column;
}

.space_between {
    justify-content: space-between;
}

.gap_1 {
    gap: 1rem;
}

.width_100 {
    width: 100%;
}

.margin_none {
    margin: 0;
}

/* Icons */
.icon_primary {
    color: var(--primary);
    cursor: pointer;
}

.icon_primary:hover,
.icon_primary:focus,
.icon_primary:active {
    color: var(--primary_hover);
}

.icon_green {
    color: green;
    cursor: pointer;
}

/* Content Layouts */
.clients_content {
    width: 91%;
    margin: 1rem auto;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}