.moduleBox {
    margin-top: 0.75rem;
    border-radius: 5px;
    padding: 1rem 2.75rem;
}

/* UsedModuleBox */
.usedModuleCon {
    padding-bottom: 10rem;
}

/* UsedModuleBlock */
.name {
    margin-right: 1rem;
}

/* UnusedModuleBox */
.unusedModuleCon h2 {
    border-top: 2px solid #0b7ea1;
    padding-top: 1.25rem;
    margin: 1rem 0 0;
}

/* UnusedModuleBlock */