.btn {
    width: 10rem;
    height: 3rem;
    border-radius: 25px;
    font-size: 1rem;
    margin: 1rem 0;
    border: none;
    color: var(--white);
    cursor: pointer;
}

.btn_primary {
    background-color: var(--primary) !important;
}

.btn_primary:hover,
.btn_primary:active,
.btn_primary:focus {
    background-color: var(--primary_hover) !important;
}

.btn_secondary {
    background-color: var(--secondary) !important;
}

.btn_secondary:hover,
.btn_secondary:active,
.btn_secondary:focus {
    background-color: var(--secondary_hover) !important;
}

.btn_primary_outline {
    border: 2px solid var(--primary);
    color: var(--primary);
    background-color: var(--white);
}

.btn_primary_outline:hover,
.btn_primary_outline:active,
.btn_primary_outline:focus {
    color: var(--white);
    background-color: var(--primary_hover);
}

/* Back Button */
.btn_back {
    display: flex;
    padding: 0.5rem;
    align-self: flex-start;
    align-items: center;
}

.btn_back_icon,
.btn_back p {
    cursor: pointer;
}

.btn_back_icon {
    color: var(--secondary);
}

.btn_back p {
    font-weight: 500;
    color: var(--secondary);
}

/* Form Buttons */
.btn_con {
    display: flex;
    gap: 2rem;
}

.btn_save,
.btn_next {
    border-radius: 15px;
    height: 3rem;
}

.btn_simple {
    text-transform: none !important;
    padding: 8px 50px !important;
}