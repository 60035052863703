.sideBurgerNav {
    color: #0b7ea1;
    background-color: transparent;
    border: none;
    padding: 0.65rem;
    align-self: flex-start;
    margin: 0.25rem 0.25rem 0 0.2rem;
    cursor: pointer;
}

.sideBurgerNav:hover, .sideBurgerNav:active, .sideBurgerNav:focus {
    border: 1.5px solid #0b7ea1;
    padding: 0.55rem;
}