.table_h2 {
    margin-bottom: 0;
}

.table_headings {
    padding: 1.5rem 3.2rem;
    margin: 0;
    background-color: var(--secondary);
    color: var(--white);
}

.table_headings th, .table_module td {
    text-align: left;
    width: 100%;
}

.table_module {
    padding: 1.5rem 3.25rem;
    margin: 0;
    background-color: var(--tertiary);
}

.table_align_center {
    align-items: center;
}