.surveyResultsCon {
    margin-bottom: 1.5rem;
}

.surveyResultsCon h2 {
    margin: 0 0 0.2rem;
}

.content {
    margin-top: 1.5rem;
}