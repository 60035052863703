.sort_fields {
    width: 100%;
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    gap: 1.25rem;
    margin: 0 auto;
}

.sort_fields h3 {
    margin: 0.5rem 0;
}

.input_con {
    width: 49%;
}

.input_fields {
    width: 100%;
    border: 2px var(--secondary) solid;
    padding: 0.25rem 1rem;
    border-radius: 5px;
}