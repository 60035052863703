/* AddNoteScreen */
.addNotesCon {
    padding: 1rem;
    margin: 0 auto;
}

.addNotesCon h2 {
    text-align: center;
    font-size: 2.25rem;
    margin: 0;
}

.addNotesCon h3 {
    margin-bottom: 0.5rem;
}

.newNoteSummary {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.writtenContent {
    margin-bottom: 1rem;
}

.addNoteBtn {
    margin: 0 auto;
}