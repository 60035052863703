.content {
    margin-top: 2rem;
}

.summary {
    text-align: center;
    margin: 0 auto 28px;
}

.summary h1, p {
    margin: 0;
}

.graphsCon {
    justify-content: space-between;
    align-items: center;
}

.graphImageCon {
    min-width: 38vw;
    height: 300px;
    background-color: #0b7ea133;
}

.trackerCon {
    padding: 0;
    gap: 2rem;
    list-style: none;
    flex-wrap: wrap;
    margin: 2rem auto;
    justify-content: space-between;
}

.trackerBox {
    width: 160px;
    height: auto;
    padding: 15px 22px 22px;
    background-color: #0b7ea133;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
}

.trackerValue, .trackerLabel {
    margin: 0;
}

.trackerValue {
    font-size: 36px;
}

.trackerLabel {
    font-size: 18px;
}

.clientProfileCon {
    gap: 1rem;
    margin-bottom: 5rem;
}