html {
  background-color: var(--white);
}

body {
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* GLOBAL COLOURS */
  --primary: #f27254;
  --primary_hover: #e86b4f;

  --secondary: #0b7ea1;
  --secondary_hover: #0A6E8C;
  --tertiary: #0b7ea133;
  --tertiary_light: #e0f8ff;

  --white: #fff;
}

a {
  text-decoration: none;
}

/* Typography */
h1 {
  font-weight: 500;
}

h2 {
  font-weight: 600;
}

h3 {
  margin: 0.5rem 0 0.25rem;
}