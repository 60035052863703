/* Pathway Builder */
.pathwayBuilderCon {
    width: 95%;
    margin: 0 auto;
    max-width: 1440px;
    margin-bottom: 4em;
}

.btnPathwayBuilder {
    align-items: center;
    align-self: center;
    justify-content: center;
}

/* Create Pathway */
.pathwayContent {
    margin: 0 auto;
    justify-content: center;
}

.createPathwayCon {
    width: 91%;
    max-width: 1440px;
    padding: 1.5rem 0;
    margin: 0 auto;
}

.createPathwayCon h1 {
    margin: 1rem 0 0;
}

/* Manage Contents */
.btn_con {
    gap: 0;
    justify-content: space-between;
}

.btn_con button {
    width: 23%;
}

/* Create Written Content */
.writtenContentCon {
    gap: 0;
    margin-top: 0.5rem;
}

/* Create Quiz */
.createQuizCon {
    flex-direction: row;
    gap: 3rem;
}

.createdQuestionsCon {
    background-color: var(--tertiary_light);
    padding: 1.5rem;
    border-radius: 5px;
    padding-left: 20px !important;
    padding-top: 15px !important;
}

.createdQuestionsCon h3 {
    margin: 0 0 0.25rem;
}

.quizTitle {
    margin: 1rem 0 0.5rem;
}

.quizQuestionCon {
    gap: 0.25rem;
}

.quizQuestionCon h2 {
    margin: 0;
}

.quizQuestionCon h4 {
    margin: 0 0 0.2rem;
}

.checkBoxCon {
    margin: 0.75rem 0 0.25rem;
    gap: 5rem;
}

.optionsBox {
    margin: 0.5rem 0;
}

.optionDescCon {
    align-items: center;
    gap: 0.5rem;
}

.optionValueCon {
    width: 60%;
}

.addQuestion {
    margin: 0.25rem 0 1.25rem;
    font-size: 1rem;
    border: none;
    color: var(--white);
    cursor: pointer;
}

/* Create Persona */
.imagesCon {
    gap: 0.75rem;
}